import React, { Component } from "react";
import { Form, Input, Button, Alert, Icon, Tooltip } from "antd";

class DomainSettings extends Component {
  state = {
    saving: this.props.saving,
  };

  componentDidUpdate() {
    if (this.state.saving !== this.props.saving) {
      this.setState({ saving: this.props.saving });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateSettings(values);
      }
    });
  };

  componentDidMount() {
    this.props.form.setFieldsValue(this.props.settings);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="smtp-form">
        <Form.Item
          label={
            <span>
              App Domain{" "}
              <Tooltip title="This is the domain where your app will be hosted. Your customers will login on this domain.">
                <Icon
                  type="info-circle"
                  theme="filled"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator("domain", {
            rules: [{ required: true, message: "This is a required field" }],
          })(
            <Input
              disabled={
                this.props.settings.status === 1 ||
                this.props.settings.status === "1"
              }
            />
          )}
        </Form.Item>
        <Form.Item
          label={
            <span>
              Wildcard Domain{" "}
              <Tooltip title="Wildcard domain is the domain where your client's stores would be hosted on different subdomains like client1.yourdomain.com or client2.yourdomain.com">
                <Icon
                  type="info-circle"
                  theme="filled"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator("wildcard", {
            rules: [{ required: true, message: "This is a required field" }],
          })(
            <Input
              disabled={
                this.props.settings.status === 1 ||
                this.props.settings.status === "1"
              }
            />
          )}
        </Form.Item>
        {(this.props.settings.status === 0 ||
          this.props.settings.status === "0") && (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.saving}
            >
              Save
            </Button>
          </Form.Item>
        )}
        <Alert
          style={{ marginTop: 30, marginBottom: 30 }}
          message="How To Point your App Domain"
          description={
            <div class="whitelabel-instructions">
              <p>1. Copy the following URL: whitelabel.zerotouch.agency</p>
              <p>2. Navigate to your domain provider</p>
              <p>3. Visit your domain management page</p>
              <p>
                4. Create a new CNAME record for your domain{" "}
                <i>(sometimes referred to as an alias)</i>
              </p>
              <p>
                5. In the name field, set the CNAME to the same value as your
                subdomain{" "}
                <i>
                  (for example, if you selected 'app' as your desired subdomain,
                  your CNAME would also be set to 'app' and your final App URL
                  would be like : app.yourdomain.com)
                </i>
              </p>
              <p>
                6. In the field labeled value, paste the domain stated in Point
                1.
              </p>
              <p>
                7. Leave all other options set at their recommended default
                settings
              </p>
            </div>
          }
          type="info"
        />
        <Alert
          style={{ marginTop: 30 }}
          message="How To Point your Wildcard Domain"
          description={
            <div class="whitelabel-instructions">
              <p>1. Copy the following URL: whitelabel.zerotouch.agency</p>
              <p>2. Navigate to your domain provider</p>
              <p>3. Visit your domain management page</p>
              <p>
                4. Create a new CNAME record for your domain{" "}
                <i>(sometimes referred to as an alias)</i>
              </p>
              <p>
                5. In the name field, set the CNAME value as '*' (without
                quotes)
              </p>
              <p>
                6. In the field labeled value, paste the domain stated in Point
                1.
              </p>
              <p>
                7. Leave all other options set at their recommended default
                settings
              </p>
            </div>
          }
          type="info"
        />
      </Form>
    );
  }
}

export default Form.create()(DomainSettings);
