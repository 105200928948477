import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { Layout, Menu, Icon } from "antd";
import "./Sidebar.scss";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

const Sidebar = function (props) {
  return (
    <Sider
      className={`sidebar ${
        props.sidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
      width={256}
      style={{ background: "rgb(247, 247, 252)" }}
      breakpoint="md"
      collapsed={!props.sidebarOpen}
      collapsedWidth={0}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{ height: "auto", borderRight: 0 }}
      >
        <Menu.Item key="users">
          <Link to={`/users`}>
            <Icon type="user" />
            Users
          </Link>
        </Menu.Item>
        <Menu.Item key="accounts">
          <Link to={`/accounts`}>
            <Icon type="user" />
            Accounts
          </Link>
        </Menu.Item>
        <Menu.Item key="settings">
          <Link to={`/settings`}>
            <Icon type="setting" />
            Settings
          </Link>
        </Menu.Item>
      </Menu>

      <Menu
        className="show-on-small-screen"
        mode="inline"
        defaultSelectedKeys={["help"]}
        defaultOpenKeys={["help"]}
        style={{ borderRight: "0px", position: "sticky", top: "100%" }}
      >
        <SubMenu
          key="user"
          title={
            <p style={{ lineHeight: "14px", margin: "6px 0" }}>
              {props.user.user_fname}
            </p>
          }
        >
          <Menu.Item key="user_logout">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                props.signOut();
              }}
            >
              <Icon type="poweroff" /> Logout
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
