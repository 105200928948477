import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import LoginForm from "./Components/Login/LoginForm/";
import Dashboard from "./Components/Dashboard/";
import Users from "./Components/Dashboard/Users";
import Accounts from "./Components/Dashboard/Accounts";
import AddUser from "./Components/Dashboard/Users/AddUser";
// import AddAccount from "./Components/Dashboard/Accounts/AddAccount";
import AddAccount from "./Components/Dashboard/LTDSettings/AddAccount";
import Settings from "./Components/Dashboard/Settings";
// import LTDSettings from './Components/Dashboard/LTDSettings';
import Migration from "./Components/Login/Migration";
import ProfileSettings from "./Components/Dashboard/ProfileSettings";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch" }} />
              )}
            />
            <Route
              path="/login"
              render={(props) => (
                <LoginForm {...{ ...props, title: "Zerotouch | Login" }} />
              )}
            />
            <Route
              exact
              path="/migration/:hash"
              render={(props) => (
                <Migration
                  {...{ ...props, title: "Zerotouch | Account Migration" }}
                />
              )}
            />

            <Route
              exact
              path="/accounts"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Accounts" }}>
                  {" "}
                  <Accounts {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/add_account"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Add Account" }}>
                  {" "}
                  <AddAccount {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/edit_account/:user_id"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Edit Account" }}>
                  {" "}
                  <AddAccount {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/users"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Users" }}>
                  {" "}
                  <Users {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/add_user"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Add User" }}>
                  {" "}
                  <AddUser {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/edit_user/:user_id"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Edit User" }}>
                  {" "}
                  <AddUser {...props} />
                </Dashboard>
              )}
            />

            {/* <Route exact path='/ltd/:tab?' render={(props) => <Dashboard {...{ ...props, title: 'Zerotouch | LTD Settings' }} > <LTDSettings {...props} /></Dashboard>} /> */}

            <Route
              exact
              path="/settings"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Settings" }}>
                  {" "}
                  <Settings {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/profile"
              render={(props) => (
                <Dashboard {...{ ...props, title: "Zerotouch | Profile" }}>
                  {" "}
                  <ProfileSettings {...props} />
                </Dashboard>
              )}
            />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
