import React, { Component } from "react";
import { Card, Alert, Row } from "antd";
import logo from "../../../assets/images/logos/logo_dark.svg";
import { Link } from "react-router-dom";
import "../LoginPageContainer.scss";
import axios from "axios";

class Migration extends Component {
  state = {
    loading: true,
    invalid_link: false,
    link_validation_error: false,
  };

  componentDidMount() {
    document.title = this.props.title;
    axios({
      url: "/user/migration_confirmation",
      method: "post",
      data: {
        hash: this.props.match.params.hash,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            invalid_link: true,
            link_validation_error: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          link_validation_error: true,
          invalid_link: false,
        });
      });
  }

  render() {
    return (
      <div className="login-page-container">
        <div className="login-container">
          <Row type="flex" justify="center">
            <img className="logo" src={logo} alt="Logo" />
          </Row>
          <Card className="signup-card" loading={this.state.loading}>
            {!this.state.loading && this.state.link_validation_error && (
              <Alert
                style={{ marginTop: 30 }}
                message="Error"
                description="There was an error. Please try refreshing the page."
                type="error"
                showIcon
              />
            )}

            {!this.state.loading && this.state.invalid_link && (
              <Alert
                style={{ marginTop: 30 }}
                message="Error"
                description="You seem to have followed an invalid link"
                type="error"
                showIcon
              />
            )}

            {!this.state.loading &&
              !this.state.link_validation_error &&
              !this.state.invalid_link && (
                <React.Fragment>
                  <Alert
                    message="Complete"
                    description="Account Migration Completed Successfully!"
                    type="success"
                    showIcon
                  />
                </React.Fragment>
              )}
          </Card>
          <div className="login-page-footer">
            <Row type="flex" justify="center">
              <p>copyright &copy; {new Date().getFullYear()} Zerotouch</p>
            </Row>
            <Row type="flex" justify="center">
              <ul className="footer-links">
                <li>
                  <Link to="/terms-conditions">Terms</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy</Link>
                </li>
                <li>
                  <Link to="/help">Help</Link>
                </li>
              </ul>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Migration;
