import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Layout,
  // Modal,
  // Button,
  // Icon
} from "antd";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
// import { ReactComponent as CEOImage } from "../../assets/images/icons/CEO.svg";
import moment from "moment";
import "./Dashboard.scss";
const { Content } = Layout;

class Dashboard extends Component {
  state = {
    sidebarOpen: window.innerWidth > 768 ? true : false,
    showWelcomeModal: false,
  };

  componentWillMount() {
    document.title = this.props.title;
    this.unlisten = this.props.history.listen((location, action) => {
      if (window.innerWidth <= 768) {
        this.setState({ sidebarOpen: false });
      }
      const lastDate = localStorage.getItem("welcomeModalLastShow");
      let showWelcomeModal = false;
      if (this.props.auth.user && !this.props.auth.user.settingsCompleted) {
        if (!moment(lastDate).isValid()) {
          showWelcomeModal = true;
        } else if (moment().diff(moment(lastDate), "days") > 7) {
          showWelcomeModal = true;
        }
      }
      this.setState(
        {
          showWelcomeModal,
        },
        () => {
          if (this.state.showWelcomeModal) {
            localStorage.setItem(
              "welcomeModalLastShow",
              moment().format("YYYY-MM-DD HH:mm:ss")
            );
          }
        }
      );
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentWillUpdate(nextProps) {
    document.title = nextProps.title;
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  }

  render() {
    const { auth } = this.props;

    if (!auth.logged_in) return <Redirect to="/login" />;
    if (
      !auth.user.settingsCompleted &&
      this.props.location.pathname !== "/settings"
    )
      return <Redirect to="/settings" />;
    if (!this.props.children) return <Redirect to={`/users`} />;

    return (
      <Layout style={{ height: "100%" }}>
        <Navbar
          user={auth.user}
          toggleSidebar={() => this.toggleSidebar()}
          toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
          toggleAddStoreModal={() => this.toggleAddStoreModal()}
        />
        <Layout>
          <Sidebar
            user={auth.user}
            sidebarOpen={this.state.sidebarOpen}
            toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
          />
          <Layout>
            <Content className="dashboard-content">
              {this.props.children}
              <Footer />
            </Content>
            {/* <Modal
							title={null}
							visible={this.state.showWelcomeModal}
							closable={false}
							footer={null}
						>
							<div style={{ textAlign: "center", padding: 30 }}>
								<CEOImage style={{ display: 'block', margin: 'auto' }} />

								<h1 style={{ margin: '15px 0' }}>Welcome to Zerotouch Whitelabel!</h1>
								<p style={{ fontSize: 18 }}><span role="img" aria-label="">👋</span>Hey {this.props.auth.user.user_fname.split(" ")[0]}!
									I’m Vipul, the CEO of LetConvert by LetX.
									I wanted to personally welcome you to LetConvert Franchise Panel.<br />
									Let’s setup your partner account to get you started right away! <span role="img" aria-label="">😉</span><br />
									Over to the setting page now!!!</p>

								<Button type="primary" size="large" onClick={() => this.props.history.push('/settings')}>Let's Go <Icon type="arrow-right" /></Button>
							</div>
						</Modal> */}
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Dashboard);
