import React, { Component } from "react";
import {
  Input,
  Table,
  Alert,
  Skeleton,
  Row,
  Col,
  Card,
  message,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
const Search = Input.Search;

class Users extends Component {
  state = {
    users: [],
    loading: false,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      page: 1,
      per_page_count: 10,
    },
  };

  fetchUsers() {
    axios({
      url: "/user/get_client_users",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            users: res.data.users,
            paging: { ...this.state.paging, total: res.data.total },
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  setUserBlocked(id, blocked) {
    axios({
      url: "/user/set_user_blocked",
      method: "post",
      data: {
        id,
        blocked: blocked ? 1 : 0,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            users: this.state.users.map((user) => {
              if (user.id === id) {
                return { ...user, user_blocked: blocked ? 1 : 0 };
              } else {
                return user;
              }
            }),
          });
          message.success("Updated Successfully!");
        } else {
          message.success("There was an error updating!");
        }
      })
      .catch((e) => {
        console.log(e);
        message.success("There was an error updating!");
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  onFilterChange(filters) {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
        loading: true,
        paging: {
          page: 1,
          per_page_count: 10,
        },
      },
      () => this.fetchUsers()
    );
  }

  handlePageChange = (pagination) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: pagination.current },
      },
      () => this.fetchUsers()
    );
  };

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <h3>Users</h3>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={{ span: 12 }} lg={{ span: 16 }}>
            {/*<Link className="ant-btn ant-btn-primary" to="/add_user">Add User</Link>*/}
          </Col>
          <Col md={{ span: 12, offset: 12 }} lg={{ span: 8, offset: 16 }}>
            <Search
              placeholder="search users"
              enterButton="Search"
              size="large"
              onSearch={(value) => this.onFilterChange({ search: value })}
            />
          </Col>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "Name",
        dataIndex: "user_fname",
      },
      {
        title: "Email",
        dataIndex: "user_email",
      },
      {
        title: "Created",
        dataIndex: "user_created",
      },
      {
        title: "Plans",
        dataIndex: "user_plan",
        render: (plans) =>
          plans.map((p, i) => (
            <span key={p}>
              {p} {i < plans.length - 1 && <span>, </span>}
            </span>
          )),
      },
      {
        title: "Accounts",
        dataIndex: "user_domains",
        render: (doms) =>
          doms.map((d, i) => (
            <span key={d.id}>
              {d.name} {i < doms.length - 1 && <span>, </span>}
            </span>
          )),
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id, row) => (
          <span>
            <Link to={`/edit_user/${id}`}>Edit</Link>
            <Switch
              style={{ marginLeft: 16 }}
              checked={row.user_blocked !== 1}
              onChange={(checked) => this.setUserBlocked(id, !checked)}
            />
          </span>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.per_page_count,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <Card>
        <Table
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={this.state.users}
          pagination={pagination}
          loading={this.state.loading}
          onChange={(pagination) => this.handlePageChange(pagination)}
        />
      </Card>
    );
  }
}

export default Users;
