import React, { Component } from "react";
import LoginPageContainer from "../LoginPageContainer";
import { connect } from "react-redux";
import { signIn } from "../../../store/actions/authActions";
import { Form, Icon, Input, Button, Tabs, Alert } from "antd";
const TabPane = Tabs.TabPane;

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
  };

  componentWillMount() {
    document.title = this.props.title;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn({ email: values.email, password: values.password });
      }
    });
  };

  render() {
    const { authError } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <LoginPageContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Log In To ZeroTouch Partners" key="1">
            <Form
              onSubmit={this.handleSubmit}
              className="login-form"
              noValidate
            >
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid Email Id!",
                    },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="email"
                    placeholder="Your Email Id"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please enter your Password!" },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Your Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {authError && <Alert message={authError} type="error" />}
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </LoginPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(LoginForm));
