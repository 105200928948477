import React, { Component } from "react";
import { Input, Table, Alert, Skeleton, Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
const Search = Input.Search;

class Accounts extends Component {
  state = {
    accounts: [],
    loading: false,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      page: 1,
      per_page_count: 10,
    },
  };

  fetchAccounts() {
    axios({
      url: "/user/get_accounts",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            accounts: res.data.accounts,
            paging: { ...this.state.paging, total: res.data.total },
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchAccounts();
  }

  onFilterChange(filters) {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
        loading: true,
        paging: {
          page: 1,
          per_page_count: 10,
        },
      },
      () => this.fetchAccounts()
    );
  }

  handlePageChange = (pagination) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: pagination.current },
      },
      () => this.fetchAccounts()
    );
  };

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <h3>Accounts</h3>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={{ span: 12 }} lg={{ span: 16 }}>
            <Link className="ant-btn ant-btn-primary" to="/add_account">
              Add Account
            </Link>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 8 }}>
            <Search
              placeholder="search accounts"
              enterButton="Search"
              size="large"
              onSearch={(value) => this.onFilterChange({ search: value })}
            />
          </Col>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "Name",
        dataIndex: "dom_name",
      },
      {
        title: "Created On",
        dataIndex: "dom_created",
      },
      {
        title: "Users Added",
        dataIndex: "dom_subusers",
        render: (users) =>
          users.map((u, i) => (
            <span key={u.email}>
              {u.name} ({u.email}) {i < users.length - 1 && <span>, </span>}
            </span>
          )),
      },
      {
        title: "Subdomain",
        dataIndex: "dom_websites",
        render: (websites) =>
          websites.map((w, i) => (
            <span key={w}>
              {w} {i < websites.length - 1 && <span>, </span>}
            </span>
          )),
      },
      // {
      //   title: "Actions",
      //   dataIndex: "id",
      //   render: (id) => (
      //     <span>
      //       <Link to={`/edit_account/${id}`}>Edit</Link>
      //     </span>
      //   ),
      // },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.per_page_count,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <Card>
        <Table
          columns={columns}
          rowKey={(record) => record.key}
          dataSource={this.state.accounts}
          pagination={pagination}
          loading={this.state.loading}
          onChange={(pagination) => this.handlePageChange(pagination)}
        />
      </Card>
    );
  }
}

export default Accounts;
